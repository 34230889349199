import { CSSProperties, ReactNode, useEffect, useMemo, useReducer, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { isEqual, omit } from 'lodash-es';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import FiltersSideMenu from 'shared/forms/FiltersSideMenu';
import { useLoadCompanyConfiguration, useProfile } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { getEnvIcon } from 'shared/utils/environment';
import { formattedDate } from 'shared/utils/view';
import { sideMenuShow } from 'store/view/view.actions';
import { Pagination } from 'types/storeTypes';

import { Title } from '@mantine/core';

import { FilterItem } from './FilterItem';
import TableFooter from './TableFooter';

import styleIdentifiers from './customTable.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TableHeader = {
  key?: string;
  title: string;
  width?: number;
};

type Props = {
  // Structure
  headers: TableHeader[];
  title?: string;
  subtitle?: string;
  pagination?: Pagination;
  actions?: ReactNode;
  customTabs?: ReactNode;
  // Data
  items: any[];
  itemProps?: Object;
  customKey?: Function;
  loaded?: boolean;
  loading?: boolean;
  filtersNames?: Object;
  resetSelection?: number;
  pendingImport?: boolean;
  // Overrides
  noCheckbox?: boolean;
  noFooter?: boolean;
  noMinHeight?: boolean;
  noShadow?: boolean;
  noSetTabTitle?: boolean;
  showShadow?: boolean;
  noMargin?: boolean;
  noResultMessage?: boolean;
  notSortable?: boolean;
  // Style
  className?: string;
  maxHeight?: string;
  style?: CSSProperties;
  // Callbacks
  loadFunc?: Function;
  loadMore?: Function;
  onCheckBoxChange?: Function;
  onToggleCheckAll?: Function;
  handleLoadData?: Function;
  setSelectAll?: Function;
  onSortChange?: Function;
  handleFilters?: Function;
  orderField?: Function;
};

type CustomTableState = {
  list: any[];
  filters: Object;
  masterCheck: boolean;
};

const reducer = (prevState, updatedProperty) => ({
  ...prevState,
  ...updatedProperty,
});

const initState: (filters: any) => CustomTableState = (filters = {}) => ({
  list: [],
  filters: {
    ...filters,
    sort_by: '',
  },
  masterCheck: false,
});

function CustomTable(ItemComponent: React.ComponentType<any>, FiltersForm?: any) {
  function Table({
    // Structure
    headers,
    title,
    subtitle,
    pagination,
    actions,
    customTabs,
    // Data
    items,
    itemProps,
    customKey,
    loaded,
    loading,
    filtersNames,
    resetSelection,
    pendingImport = false,
    // Overrides
    noCheckbox,
    noFooter,
    noMinHeight,
    noShadow,
    noSetTabTitle = false,
    showShadow,
    noMargin,
    noResultMessage,
    notSortable,
    // Style
    className,
    maxHeight,
    style,
    // Callbacks
    loadFunc,
    loadMore,
    onCheckBoxChange,
    onToggleCheckAll,
    handleLoadData,
    setSelectAll,
    onSortChange,
    handleFilters,
    orderField,
  }: Props) {
    const history = useHistory();
    const location = useLocation();
    const profile = useProfile();
    const { company } = useLoadCompanyConfiguration();
    const { t, currentLang } = useTranslation();
    const [state, setState] = useReducer(
      reducer,
      initState(qs.parse(location.search, { arrayFormat: 'comma' })),
    );
    const [timeoutDebounce, setTimeoutDebounce] = useState<NodeJS.Timeout>();
    const [filterOpen] = useState(false);
    const [selectAll, updateSelectAll] = useState(false);

    useEffect(() => {
      if (!noSetTabTitle) {
        document.title = `${getEnvIcon()}${company.name} - ${title}`;
      }

      return function cleanup() {
        if (!noSetTabTitle) {
          document.title = `${getEnvIcon()}${company.name ?? 'Recovr'}`;
        }
      };
    }, [title]);

    useEffect(() => {
      if (resetSelection) {
        list.forEach((element) => {
          onItemClick(new Event(''), element.id, false, true);
        });
        setState({
          masterCheck: false,
        });
        onCheckBoxChange?.([]);
      }
    }, [resetSelection]);

    const resetFilters = () => {
      filterObject?.reset();
      applyFilters({});
    };
    useEffect(() => {
      if (timeoutDebounce) {
        clearTimeout(timeoutDebounce);
      }
      const timeout = setTimeout((): void => {
        loadData();
      }, 500);

      setTimeoutDebounce(timeout);
    }, [location]);

    useEffect(() => {
      updateList();
    }, [items]);

    const onItemClick = (e: Event, id: number, targetValue?: boolean, notSend?: boolean) => {
      const { list } = state;

      e.stopPropagation();

      const index = list.findIndex((item) => item.id === id);
      const newElement = list[index];

      let isMasterCheck = true;
      // toggle part
      if (targetValue !== undefined) newElement.checked = targetValue;
      else if (newElement.checked === true) {
        newElement.checked = false;
      } else {
        newElement.checked = true;
      }
      list.splice(index, 1, newElement);

      if (!targetValue) {
        list.forEach((element) => {
          if (!element.checked) isMasterCheck = false;
        });
        setState({ list, masterCheck: isMasterCheck });
      }
      if (!notSend) onCheckBoxChange!(list.filter((item) => item.checked));
    };

    // Update location
    const updateUrl = (filters) => {
      history.push({
        pathname: location.pathname,
        search: qs.stringify(filters, { arrayFormat: 'comma' }),
        hash: location.hash,
      });
    };

    // Update the list displayed internally
    const updateList = () => {
      const { filters } = state;

      setState({
        list: items || [],
        filters: {
          ...filters,
          sort_by: (orderField ? orderField(filters.sort_by || '') : filters.sort_by) || '',
          sort_order: filters.sort_order || 'asc',
        },
      });
    };

    const loadData = () => {
      loadFunc?.({
        view: location.hash.slice(1),
        ...(handleLoadData ? handleLoadData(qs.parse(location.search)) : qs.parse(location.search)),
      });
    };

    const applyFilters = (values) => {
      const { filters } = state;

      const newFilters = handleFilters ? handleFilters(values, filters) : values;
      if (newFilters) {
        if (!isEqual(omit(filters, 'page'), omit(newFilters, 'page'))) {
          newFilters.page = 1;
        }

        for (const key in newFilters) {
          !newFilters[key] && delete newFilters[key];
        }

        newFilters.page_limit = values.page_limit || filters.page_limit;
        updateUrl(newFilters);
        setState({
          filters: newFilters,
        });
      }
    };

    const masterCheck = (e) => {
      const { masterCheck } = state;
      const { list } = state;

      onToggleCheckAll?.(!masterCheck);

      list.forEach((element) => {
        onItemClick(e, element.id, !masterCheck, true);
      });

      onCheckBoxChange?.(list.filter((item) => item.checked));
      setState({
        masterCheck: !masterCheck,
      });
    };

    const setOrder = (field: string) => () => {
      const { filters } = state;

      const { sort_by, sort_order } = filters;

      const newFilters = {
        ...filters,
        sort_by: field,
        sort_order: sort_by === field && sort_order === 'asc' ? 'desc' : 'asc',
      };

      setState({
        filters: newFilters,
      });

      updateUrl(newFilters);

      if (onSortChange) {
        onSortChange(newFilters);
      }
    };

    const { filters, list } = state;

    let filtersNumber = -1;
    for (const key in filters) {
      key !== 'page' && filters[key] && filtersNumber++;
    }

    const filterObject = {
      reset: () => {},
    };

    const setFilterOpen = (open: boolean) => {
      if (open) {
        const initValues: any = filters;
        sideMenuShow({
          unmount: true,
          content: (
            <FiltersSideMenu onRemoveFilter={resetFilters}>
              <FiltersForm
                filterObject={filterObject}
                onSubmit={applyFilters}
                initialValues={initValues}
                {...itemProps}
              />
            </FiltersSideMenu>
          ),
        });
      }
    };

    const onSetSelectAll = () => {
      setSelectAll!(!selectAll);
      updateSelectAll(!selectAll);
    };

    const setPage = (page) => {
      const newPage = parseInt(page.selected, 10) + 1;

      const { filters } = state;
      const newFilters = {
        ...filters,
        page: newPage,
      };

      applyFilters(newFilters);
      updateUrl(newFilters);

      if (!selectAll) {
        setState({
          masterCheck: false,
        });
        onCheckBoxChange?.([]);
      }
    };

    const setPageLimit = (page_limit) => () => {
      applyFilters({ ...state.filters, page_limit });
    };

    const filterToShow = useMemo(
      () =>
        Object.entries(filters).filter(
          ([key, value]) => !['sort_order', 'sort_by', 'page', 'page_limit'].includes(key),
        ),
      [filters],
    );

    const noResult = pendingImport ? null : noResultMessage || <>{t(i18nKeys.NO_RESULT)}</>;

    return (
      <div
        className={styles(
          'CustomTable',
          noShadow && 'no-shadow',
          showShadow && 'show-shadow',
          noMargin && 'no-margin',
          className,
          noMinHeight && 'no-min-height',
        )}
      >
        {(title || subtitle) && (
          <div className={styles('head', noCheckbox && 'no-checkbox')}>
            {title && <Title order={3}>{title}</Title>}
            {subtitle && <Title order={4}>{subtitle}</Title>}
            <div className={styles('actions')}>
              {actions}
              {(filtersNumber > 0 || FiltersForm) && (
                <div className={styles('filters-actions')}>
                  {filtersNumber > 0 && (
                    <Button
                      className={styles('remove-filter', 'filter')}
                      noMargin
                      small
                      iconSize="16px"
                      color={ButtonColor.WHITE}
                      onClick={resetFilters}
                    >
                      <Icon name={IconName.FILTER} />
                      <Icon name={IconName.SMALL_REMOVE} />
                    </Button>
                  )}
                  {FiltersForm && (
                    <Button
                      className={styles('filter', filterOpen && 'open')}
                      noMargin
                      small
                      iconSize="16px"
                      color={ButtonColor.WHITE}
                      iconLeft={IconName.FILTER}
                      onClick={() => {
                        setFilterOpen(!filterOpen);
                      }}
                    >
                      {filtersNumber > 0 && (
                        <div className={styles('text-circle', 'absolute', 'border', 'badge')}>
                          {filtersNumber}
                        </div>
                      )}
                      {t(i18nKeys.FILTER)}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {FiltersForm && (
          <div className={styles('filters')}>
            <div className={styles('filters-container')}>
              {filterToShow.map((data) => {
                const [key] = data;

                // Skip if end date
                if (key.includes('_before') && filters[key.replace('_before', '_after')]) {
                  return null;
                }

                let values = Array.isArray(filters[key]) ? filters[key] : [filters[key]];

                // Merge if start date
                if (key.includes('_after') && filters[key.replace('_after', '_before')]) {
                  values = [...values, filters[key.replace('_after', '_before')]].map((e) =>
                    formattedDate(e, currentLang),
                  );
                }

                if (filtersNames) {
                  if (filtersNames[key]?.value) {
                    values = values.map(
                      (el) =>
                        filtersNames[key].value.find((e) => String(e.value) === String(el))
                          ?.description,
                    );
                  }
                }

                return (
                  <FilterItem
                    key={key}
                    label={filtersNames ? filtersNames[key].description : key}
                    value={
                      key.includes('_after')
                        ? `${values[0]} - ${values[1]}`
                        : values.reduce((acc, _key, i) => (i > 0 ? `${acc}, ${_key}` : _key), '')
                    }
                    onClick={() => setFilterOpen(!filterOpen)}
                    onClickDelete={() => applyFilters(omit(state.filters, key))}
                  />
                );
              })}
            </div>
          </div>
        )}
        {customTabs}
        <div className={styles('listing-wrapper')}>
          <div className={styles('card-style', 'card')}>
            <div className={styles('listing', noShadow && 'no-shadow')} style={{ maxHeight }}>
              <table style={style}>
                <thead>
                  <tr>
                    <th className={styles('box-wrapper', noCheckbox && 'small')}>
                      <div className={styles('not-sortable-head')}>
                        {!noCheckbox && (
                          <div
                            onClick={masterCheck}
                            className={styles('box', 'checkbox', state.masterCheck && 'checked')}
                          />
                        )}
                      </div>
                    </th>
                    {headers.map((item: any, index) => (
                      <th
                        key={item.title || index}
                        style={{ width: item.width }}
                        className={styles(
                          index === headers.length - 1 && 'last',
                          index === 0 && 'first',
                        )}
                      >
                        {item.key ? (
                          <div
                            className={styles('sortable-head', notSortable && 'no-pointer')}
                            onClick={!notSortable ? setOrder(item.key) : () => {}}
                          >
                            {item.title}
                            {filters.sort_by === item.key && (
                              <Icon
                                name={IconName.TAILDOWN}
                                size="15px"
                                className={styles(
                                  'arrow',
                                  filters.sort_order === 'asc' && 'inverted',
                                )}
                              />
                            )}
                          </div>
                        ) : (
                          <div className={styles('not-sortable-head')}>{item.title}</div>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {setSelectAll &&
                    state.masterCheck &&
                    pagination &&
                    pagination.total_objects >
                      (state.filters.page_limit || profile.preferences.itemsPerPage) && (
                      <tr>
                        <td className={styles('select-all')} colSpan={100}>
                          <div>
                            <div>
                              {t(
                                (selectAll ? pagination!.total_objects : items.length) === 1
                                  ? i18nKeys.SELECTED_ELEMENT
                                  : i18nKeys.SELECTED_ELEMENTS,
                                {
                                  count: selectAll ? pagination!.total_objects : items.length,
                                },
                              )}
                              <span onClick={onSetSelectAll}>
                                {t(
                                  (selectAll ? items.length : pagination!.total_objects) === 1
                                    ? i18nKeys.SELECT_ELEMENT
                                    : i18nKeys.SELECT_ELEMENTS,
                                  {
                                    count: selectAll ? items.length : pagination!.total_objects,
                                  },
                                )}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  {list.map((item, key: number) => (
                    <ItemComponent
                      index={key}
                      headers={headers}
                      action={(e) => onItemClick(e, item.id)}
                      item={item}
                      key={customKey ? customKey!(item) : item.id || key}
                      noCheckbox={noCheckbox}
                      onCheckBoxChange={onCheckBoxChange}
                      reloadInfo={loadData}
                      {...itemProps}
                    />
                  ))}
                </tbody>
              </table>
              {loading && <div className={styles('loading')}>{t(i18nKeys.LOADING)}</div>}
              {loaded && list.length === 0 && (
                <div className={styles('no-result')}>
                  {filtersNumber > 0 ? t(i18nKeys.NO_RESULT_FOR_THIS_SEARCH) : noResult}
                  {pendingImport && (
                    <>
                      <div className={styles('pending')}>
                        <Icon
                          name={IconName.SYNC}
                          color={IconColor.BLUE}
                          rotate
                          absolute
                          size="24px"
                        />
                      </div>
                      <p>{t(i18nKeys.PENDING_IMPORT)}</p>
                    </>
                  )}
                </div>
              )}
            </div>
            {!noFooter && pagination && (
              <TableFooter
                pagination={pagination}
                setPage={setPage}
                itemsLength={list.length}
                setPageLimit={setPageLimit}
              />
            )}
          </div>
        </div>
        {loadMore && pagination && pagination.current_page < pagination.last_page && (
          <div className={styles('buttons')}>
            <Button
              noMargin
              label={t(i18nKeys.SHARED.CUSTOM_TABLE.LOAD_MORE)}
              onClick={() => loadMore(pagination)}
            />
          </div>
        )}
      </div>
    );
  }
  return Table;
}

export default CustomTable;
