import { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';

import { Title } from '@mantine/core';

import styleIdentifiers from './PaymentPlanForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type PaymentPlanFormWrapperProps = {
  actions?: ReactNode;
  className?: string;
  edit?: boolean;
};

export const PaymentPlanFormWrapper = ({
  edit,
  actions,
  className,
  children,
}: PropsWithChildren<PaymentPlanFormWrapperProps>) => {
  const { t } = useTranslation();

  return (
    <div className={styles('payment-plan-form', className)}>
      <div className={styles('head')}>
        <div>
          <Title order={3}>{t(i18nKeys.FORM.PAYMENT_PLAN[edit ? 'EDIT' : 'NEW'].TITLE)}</Title>
          <p className={styles('subhead')}>
            {t(i18nKeys.FORM.PAYMENT_PLAN[edit ? 'EDIT' : 'NEW'].WARNING)}
          </p>
        </div>
        {actions && <div className={styles('actions')}>{actions}</div>}
      </div>
      {children}
    </div>
  );
};
