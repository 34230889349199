import { useEffect } from 'react';
import { useAuthenticateDomain, useDeleteDomain, useGetEmailDomain } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { UpsellIcon } from 'shared/components';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor, Input } from 'shared/io';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { Box, Button as MantineButton, Group, Text, Title } from '@mantine/core';

import { CustomDomainFields, CustomDomainFieldsName } from '../PreferencesForm.constants';

import { DnsSettings } from './DnsSettings';

import styleIdentifiers from '../PreferencesForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const CustomDomainPartialForm = () => {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const canUseCustomDomain = company.package.can_use_custom_domain;

  const { data: customDomainResponse, refetch } = useGetEmailDomain();

  const { mutate: authenticateDomain } = useAuthenticateDomain();
  const { mutate: deleteDomain } = useDeleteDomain();

  const { register, watch, setValue } = useFormContext<CustomDomainFields>();

  const { data: customDomain } = customDomainResponse || {};

  const onSubmit = () => {
    authenticateDomain(
      { data: { custom_domain: watch('custom_domain') } },
      {
        onSuccess: () => {
          refetch();
        },
      },
    );
  };

  useEffect(() => {
    if (customDomain) {
      setValue(CustomDomainFieldsName.CUSTOM_DOMAIN, customDomain.attributes.domain);
    }
  }, [customDomain]);

  const openDnsSettings = () => {
    if (customDomain) {
      showDialog({
        id: DialogShowId.CUSTOM,
        size: DialogShowSize.MEDIUM,
        title: t(i18nKeys.CUSTOM_DOMAIN.DNS_SETTINGS),
        children: <DnsSettings domainId={customDomain.id} />,
      });
    }
  };

  const handleDeleteDomain = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_DOMAIN)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.DELETE)}
          onConfirm={() =>
            deleteDomain(
              { id: customDomain!.id },
              {
                onSuccess: () => {
                  refetch();
                },
              },
            )
          }
        />
      ),
    });
  };

  const customDomainValue = watch('custom_domain');
  // https://regexr.com/3au3g
  const domainRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
  const isDomainValid = customDomain && customDomain.attributes.domain_valid;

  return (
    <>
      <Group>
        <Title order={6} mt={4}>
          {t(i18nKeys.CUSTOM_DOMAIN.SECTION_TITLE)}
        </Title>
        {canUseCustomDomain ? null : <UpsellIcon />}
      </Group>
      <Group w="100%" align="end" opacity={canUseCustomDomain ? 1 : 0.5}>
        <Box flex={0.5}>
          <Input
            register={register(CustomDomainFieldsName.CUSTOM_DOMAIN)}
            label={t(i18nKeys.CUSTOM_DOMAIN.NAME)}
            disabled={!canUseCustomDomain || Boolean(customDomain)}
            title={canUseCustomDomain ? '' : t(i18nKeys.NOT_INCLUDED)}
            noMargin
          />
        </Box>
        {customDomain ? (
          <>
            <Button
              onClick={handleDeleteDomain}
              label={(<Icon name={IconName.TRASH_SIMPLE} />) as any}
              color={ButtonColor.RED}
              className={styles('delete-button')}
              small
              noMargin
            />
            <Button
              onClick={openDnsSettings}
              label={t(i18nKeys.CUSTOM_DOMAIN.VERIFY)}
              color={ButtonColor.BLUE}
              small
              noMargin
            />
          </>
        ) : (
          <MantineButton
            onClick={onSubmit}
            disabled={
              customDomainValue === '' ||
              customDomainValue === null ||
              !domainRegex.test(customDomainValue)
            }
            size="xs"
            variant="outline"
          >
            {t(i18nKeys.CUSTOM_DOMAIN.AUTHENTICATE)}
          </MantineButton>
        )}
      </Group>
      {customDomain && (
        <Group align="end">
          <Box flex={0.5}>
            <Input
              register={register(CustomDomainFieldsName.DEFAULT_FROM)}
              label={t(i18nKeys.CUSTOM_DOMAIN.DEFAULT_FROM)}
              noMargin
              disabled={!isDomainValid}
              placeholder={
                isDomainValid ? 'domain.com' : t(i18nKeys.CUSTOM_DOMAIN.NOT_YET_VERIFIED)
              }
            />
          </Box>
          <Text>@{customDomain.attributes.domain}</Text>
        </Group>
      )}
    </>
  );
};
